import api from "../../utils/Api";
import { storeToken } from "../../utils/Auth";

export const login = async (paymentId) => {
    try {
        const credentials = {
            data: {
                grant_type: process.env.REACT_APP_GRANT_TYPE,
                scope: process.env.REACT_APP_SCOPE,
                merchant_id: process.env.REACT_APP_PARTNER_ID,
                payment_id: paymentId
            },

            auth: {
                username: process.env.REACT_APP_CLIENT_ID,
                password: process.env.REACT_APP_CLIENT_SECRET
            }
        }

        let response = await api({
            url: '/oauth/token',
            method: 'post',
            data: credentials.data,
            auth: credentials.auth
        })

        const accessToken = response.data.access_token;

        //storeToken(accessToken);

        return accessToken

    } catch (err) {
        console.log("🚀 ~ login ~ err:", err)
        throw err
    }
}