import { terminateSession } from "../../utils/Auth";
import {
    GET_AUTH,
    GET_PAYMENT_ID,
    REDIRECT_URL,
    REMOVE_AUTH,
    TERMINATE_SESSION
} from "./AuthTypes";

export const requestAuth = (payload) => {
    return {
        type: GET_AUTH,
        payload
    };
}

export const terminate = () => {
    terminateSession();
    return {
        type: TERMINATE_SESSION,
        payload: null
    };
}

export const removeAuth = () => {
    return {
        type: REMOVE_AUTH
    }
}

export const redirectUrl = (allow) => {
  return {
    type: REDIRECT_URL,
    allow
  };
};

export const checkoutPaymentId = (paymentId) => {
  return {
    type: GET_PAYMENT_ID,
    payload : paymentId
  };
};