import * as types from './PaymentTypes';

const INITIAL_STATE = {
    data: null,
    bankFee: null,
    method: null,
    processor: null,
    wallet: null,
    referenece: null,
    verificationUrl: "",
    updateData: null,
    isLoading: false,
    formValues: {},
    formIsValid: false,
    error: {
        flag: false,
        msg: null,
        status: null
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_PAYMENT:
            return {
                ...state,
                isLoading: true,
                data: null,
                error: {
                    flag: false,
                    msg: null,
                    status: null
                }
            }
        case types.GET_PAYMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.data,
            }
        case types.GET_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: {
                    flag: true,
                    msg: action.error,
                    status: action.error.response.status,
                    code: action.error.response.data.code
                }
            }
        case types.UPDATE_PAYMENT:
            return {
                ...state,
                isLoading: true,
                updateData: null,
                error: {
                    flag: false,
                    msg: null
                }
            }
        case types.UPDATE_PAYMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                updateData: action.payload
            }
        case types.UPDATE_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: {
                    flag: true,
                    msg: action.error.response.data.message
                }
            }
        case types.SET_BANKFEE:
            return {
                ...state,
                bankFee: action.payload
            }
        case types.SET_METHOD:
            return {
                ...state,
                method: action.payload
            }
        case types.SET_REFERENCE:
            return {
                ...state,
                reference: action.payload
            }
        case types.SET_PROCESSOR:
            return {
                ...state,
                processor: action.payload
            }
        case types.SET_PROCESSOR:
            return {
                ...state,
                processor: action.payload
            }
        case types.CREATE_PAYMENT:
            return {
                ...state,
                isLoading: true,
            }
        case types.CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                verificationUrl: action.payload,
                error: {
                    flag: false,
                    msg: null
                }
            }
        case types.CREATE_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            }
        case types.GET_PAYMENT_PROCESSOR:
            return {
                ...state,
                method: action.method,
                isLoading: true
            }
        case types.GET_PAYMENT_PROCESSOR_SUCCESS:
            return {
                ...state,
                processor: action.payload
            }
        case types.GET_PAYMENT_PROCESSOR_FAILED:
            return {
                ...state,
                method: action.method,
                isLoading: false,
                error: {
                    flag: true,
                    msg: action.error.response
                }
            }
        case types.PAYMENT_WALLET_CREATE:
            return {
                ...state,
                isLoading: true
            }
        case types.PAYMENT_WALLET_CREATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                wallet: action.payload
            }
        case types.PAYMENT_WALLET_CREATE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: {
                    flag: false,
                    msg: action.error.response
                }
            }
        case types.SET_FORM_VALUES:
            return {
                ...state,
                formIsValid: action.payload.isValid,
                formValues: action.payload.values
            }
        case types.LOG_PAYMENT_ATTEMPT_SUCCESS:
            return {
                ...state,
            }
        case types.CHECK_TRANSACTION_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                transactionStatus: action.payload.debug_msg
            }
        case types.CHECK_TRANSACTION_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: {
                    flag: false,
                    msg: action.error.response
                }
            }
        default:
            return state;
    }
}