
import React, { useCallback, useEffect, useState } from "react";
import { Container, Grid, makeStyles, Typography, Button, useMediaQuery} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles'
import constant from '../../utils/Constants';
import paymentLogo from '../../assets/payment/payment@3x.png'
import failedLogo from '../../components/assets/failedLogo.svg'
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"
import { checkTransactionStatus, invalidatePayment, updatePayment } from '../../store/Payment/PaymentAction';
import ModalBase from '../../components/ModalBase/ModalBase';
import urlEncode from '../../utils/UrlEncode';
import { redirectUrl } from "../../store/Auth/AuthAction";


const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        margin: 'auto',
        textAlign: 'center',
        color: '#58595B',
        marginTop: '-10vh',
    },
    logo: {
        width: 117,
        height: 84
    },
    container: {
        height: '10vh',
        minHeight: '100%',
        maxWidth: '100%',

    },
    pendingHeader: {
        paddingTop: '20px',
        paddingBottom: '10px',
        paddingLeft: '20px',
        paddingRight: '20px',
        color: '#373D3F',
        [theme.breakpoints.down("xs")]: {
            fontSize: 15,
        },
    },
  
    pendingText: {
        color: '#646567',
        fontSize: 13,
        fontWeight: 200,
        lineHeight: 2,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: 10
        },
    },

    btnAction: {
        letterSpacing: '0.69px',
        font: 'normal normal normal 14px/21px Poppins',
        width: '224px',
        height: '42px',
        borderRadius: '21px',
        borderColor: '#0077C0',
        textTransform: 'none',
    },

    btnContainer: {
        paddingTop: 30,
    },

}));

const PendingTransaction = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()
    const dispatch = useDispatch()
    const payment = useSelector(state => state.payment)
    const paymentUpdate = useSelector(state => state.payment.updateData)
    const paymentStatus = useSelector(state => state.payment.transactionStatus)
    const matches = useMediaQuery(theme.breakpoints.down('xs'))
    const [urlApp, setUrlApp] = useState('')
    const [counter, setCounter] = useState(process.env.REACT_APP_SPP_TIMEOUT)
    const [isJumped, setIsJumped] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    const onClickRedirect = useCallback(() => {
        if (isAndroid) {
            // const url = `intent:${urlApp}#Intent;scheme=${urlApp};package=${process.env.REACT_APP_SPP_PACKAGE_NAME};end`;
            // window.location.replace(url);

            window.location.replace(urlApp);
            setTimeout(() => {
                window.location.replace(`intent:#Intent;scheme=${urlApp};package=com.shopee.ph;end`);
            }, 3000);
        } 
        else if (isIOS) {
            window.location.replace(urlApp);
            setTimeout(() => {
                window.location.replace("https://apps.apple.com/ph/app/shopee-ph/id959841854");
            }, 3000);
        } 
        else {
            window.location.replace("https://shopee.ph/");
        }
    })

    const handlePaymentStatus = () => {
        if (paymentStatus && paymentStatus.toUpperCase() !== constant.payment_success) {
            history.push(`/status/failed?paymentId=${payment.data.paymentId}`)
            dispatch(invalidatePayment(payment.processor.paymentMethodName, payment.data.paymentId, isMobile))
        } else {
            if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
                console.log('Payment Status: ', paymentStatus)
            }
        }
    }

    const handleBackOnClick = () => {
        dispatch(updatePayment({ paymentId: payment.data.paymentId }, constant.payment_canceled))
        dispatch(invalidatePayment(payment.processor.paymentMethodName, payment.data.paymentId, isMobile))
    }

    useEffect(()=> {
        setUrlApp(payment.wallet.redirect_url_app)
        if (!isJumped) {
            setTimeout(() => {
                onClickRedirect()
                setIsJumped(true)
            }, 100);
        }
    })

    useEffect(() => {
        if (counter > 0 && (!paymentStatus || paymentStatus.toUpperCase() !== constant.payment_success)) {
            setTimeout(() => setCounter(counter - 1), 1000);
        } else {
            handlePaymentStatus()
            setCounter(0)
        }
    })

    useEffect(() => {
        if (counter > 0 && counter % 5 === 0) {
            dispatch(checkTransactionStatus(payment.processor.paymentMethodName, payment.data.paymentId))
            if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
                console.log('Time remaining: ', counter)
            }
        }
    }, [counter])

    useEffect(() => {
        if (paymentUpdate && paymentUpdate.status === 'CANCELLED') {
            dispatch(redirectUrl(true)) 
            history.push(`/redirect?${urlEncode('q', `${payment.data.redirectUrl.cancel}?paymentId=${payment.data.paymentId}&status=CANCELLED&referenceNumber=${paymentUpdate.referenceNumber}&errorCode=${paymentUpdate.error.code}&errorMessage=${paymentUpdate.error.message}`)}&exit=true`)
        }
    }, [paymentUpdate])

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname)
        window.addEventListener('popstate', onBackButtonClick)
        return () => {
            window.removeEventListener('popstate', onBackButtonClick)
        }
    }, []);

    const onBackButtonClick = (e) => {
        e.preventDefault()
        setModalOpen(true)
        window.history.pushState(null, null, window.location.pathname)
    }

    return (
        <div id="div-pending">
           <Container id="container-pending" className={classes.container}>
                <Grid
                    id="grid-container-pending"
                    container
                    component="main"
                    className={classes.root}
                    direction='column'
                    justify='center'
                    alignItems='center'
                >
                    <Grid
                        id="grid-container-pending-content"
                        container
                        direction='column'
                    >
                        <Grid item id="grid-item-img">
                            <img id="img-paymentLogo" className={classes.logo} src={paymentLogo}></img>
                        </Grid>

                        <Grid item id="grid-item-header" className={classes.pendingHeader}>
                            <Typography variant='h4'>
                                {constant.pending_header}
                            </Typography>
                        </Grid>
                        <Grid item id="grid-item-body" className={classes.pendingTextContainer}>
                            <Typography id="typography-body1" variant='subtitle2' className={classes.pendingText}>
                                {constant.pending_body1}
                            </Typography>
                            <Typography id="typography-body2" variant='subtitle2' className={classes.pendingText}>
                                {constant.pending_body2}
                            </Typography>
                        </Grid>
                        
                    </Grid>
                    <Grid
                            container
                            direction='column'
                            justify='center'
                            alignItems='center'
                            spacing={3}
                            className={classes.btnContainer}
                            id="grid-container-return"
                        >
                        <Grid item id="grid-item-return">
                            <Button color="primary" variant='outlined' className={classes.btnAction} id="button-back" onClick={() => onClickRedirect()} >
                                {constant.pending_return_text}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <ModalBase open={modalOpen} setModalOpen={setModalOpen} handleYesModal={handleBackOnClick} logo={failedLogo} actionText={constant.from_end_action_text} bodyText={constant.from_end_body_text} headerText={constant.from_end_header_text}></ModalBase>
            </Container>
        </div>
    );
}

export default PendingTransaction;