import { purgeStoredState } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import Cookies from 'js-cookie'

const persistConfig = {
    key: 'bayadPersist',
    storage: storage,
    whitelist: ['token', 'payment', 'pgwData'] // which reducer want to store
};

export const terminateSession = () => {
    Cookies.remove('token');
    Cookies.remove('paymentId');
    Cookies.remove('session')
    localStorage.removeItem('persist:bayadPersist')
}

export const storePaymentId = (paymentId) => {
    Cookies.set('paymentId', paymentId, { secure: true })
}

export const getPaymentId = () => {
    return Cookies.get('paymentId')
}

export const getLocalPaymentId = () => {
    let localData = JSON.parse(localStorage.getItem('persist:bayadPersist'))
    let tokenObj = JSON.parse(localData.token);
    return tokenObj.paymentId
}

export const storeToken = (accessToken) => {
    var expire = new Date(new Date().getTime() + 60 * 60 * 1000);
    Cookies.set('token', accessToken, { expires: expire, secure: true })
}

export const getToken = () => {
    return Cookies.get('token')
}

export const getLocalToken = () => {
    let accessToken = null
    let localData = JSON.parse(localStorage.getItem('persist:bayadPersist'))
    if(localData){
        let tokenObj = JSON.parse(localData.token);
        accessToken = tokenObj.accessToken
    }
    return accessToken
}

export const setSession = () => {
    var expire = new Date(new Date().getTime() + 50 * 60 * 1000);
    Cookies.set('session', 'session', { sameSite: 'strict', expires: expire, secure: true })
}

export const getSession = () => {
    return Cookies.get('session')
}

export const setPgwId = (id) => {
    var expire = new Date(new Date().getTime() + 50 * 60 * 1000);
    Cookies.set('pgw-id', id, { sameSite: 'strict', expires: expire, secure: true })
}

export const getPgwId = () => {
    return Cookies.get('pgw-id')
}

export const setSessionId = () => {
    sessionStorage.setItem('sessionId', Date.now().toString())
}

export const getSessionId = () => {
    return sessionStorage.getItem('sessionId')
    
}

export const clearSessionId = () => {
    sessionStorage.removeItem('sessionId')
}