import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import BaseStatus from '../../../../components/BaseStatus/BaseStatus';
import { redirectUrl, requestAuth } from '../../../../store/Auth/AuthAction';
import { getPayment, updatePayment } from '../../../../store/Payment/PaymentAction';
import { getLocalPaymentId } from '../../../../utils/Auth';
import constant from '../../../../utils/Constants';
import { useHistory } from 'react-router-dom';
import { Prompt } from 'react-router'
import Loader from '../../../Loader/Loader';
import AcknowledgementFailed from './AcknowledgementFailed';
import urlEncode from '../../../../utils/UrlEncode';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

function Failed(props) {
    const payment = useSelector(state => state.payment.data)
    const query = useQuery()
    const paymentErr = useSelector(state => state.payment.error)
    const pgw = useSelector(state => state.pgw)
    const dispatch = useDispatch()
    const paymentUpdate = useSelector(state => state.payment.updateData)
    const auth = useSelector(state => state.auth)
    const [redirect, setRedirect] = useState(null)
    const history = useHistory()
    const [isBackButtonClicked, setBackbuttonPress] = useState(false)

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }
    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        setBackbuttonPress(true)
        window.history.pushState(null, null, window.location.pathname);
    }

    useEffect(() => {
        let paymentId = getLocalPaymentId()
        if (!paymentId) {
            if (process.env.REACT_APP_GET_PARAMS == 1) {
                paymentId = query.get('paymentId')
            }
        }
        if (!paymentUpdate) {
            dispatch(requestAuth({
                justAuth: true,
                paymentId: paymentId,
                checkDuplicate: false,
                executeUpdate: true,
                updateData: { paymentId: paymentId, status: constant.payment_success }
            }))
        }

        if (paymentUpdate) {
            let errorCode = null
            if (pgw.error.data) {
                errorCode = pgw.error.data.code
            }

            if (paymentUpdate.status === 'EXPIRED') {
                const symbol = paymentUpdate.redirectUrl.cancel.includes('?') ? '&' : '?'
                dispatch(redirectUrl(true)) 
                history.push(`/redirect?${urlEncode('q', paymentUpdate.redirectUrl.cancel + symbol + "paymentId=" + paymentId + "&status=CANCELLED&referenceNumber=" + payment.referenceNumber + "&errorCode=50001&errorMessage=User explicitly cancelled the transaction")}&exit=true`)
            }

            const symbol = paymentUpdate.redirectUrl.failure.includes('?') ? '&' : '?'
            setRedirect(`${paymentUpdate.redirectUrl.failure}${symbol}paymentId=${paymentId}&status=FAILED&referenceNumber=${paymentUpdate.referenceNumber}&error=${paymentUpdate.error.code}&errorMessage=${paymentUpdate.error.message}`)
        }

        if (paymentErr.flag || auth.error.flag) {
            const symbol = paymentUpdate.redirectUrl.failure.includes('?') ? '&' : '?'
            setRedirect(`${paymentUpdate.redirectUrl.failure}${symbol}paymentId=${paymentId}&status=FAILED&referenceNumber=${paymentUpdate.referenceNumber}&error=${paymentUpdate.error.code}&errorMessage=${paymentUpdate.error.message}`)
        }



    }, [payment, paymentUpdate])

    return (
        redirect ? <div>
            <AcknowledgementFailed
                expiredSession={false}
                title={paymentUpdate && (paymentUpdate.error.type === "Issuer" ? paymentUpdate.error.message : constant.status_failed_title_non_issuer)}
                paymentUpdate={paymentUpdate && paymentUpdate}
                type={paymentUpdate && paymentUpdate.error.type}
                body={paymentUpdate && (paymentUpdate.error.type === "Issuer" ? constant.statue_failed_issuer2 : '')}
                merchantLink={redirect && redirect}
                redirect={true}
                isBackButtonClicked={isBackButtonClicked}
                setBackbuttonPress={setBackbuttonPress}
            />
        </div> : <Loader getAuth={false} loader_title={constant.loader_title} loader_body={constant.updating_payment_body} />

    );
}

export default Failed;