import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { terminate } from '../../store/Auth/AuthAction';
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { getLocalPaymentId } from '../../utils/Auth';
import { getPayment } from '../../store/Payment/PaymentAction';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const RedirectTo = (props) => {
    const dispatch = useDispatch();
    const query = useQuery();
    const history = useHistory();
    const redirectUrl = useSelector(state => state.token.redirectUrl)

    useEffect(() => {
        if(redirectUrl){
            if (query.get('exit'))
                dispatch(terminate());

            console.log(query.get('q'))
            console.log(window.parent, 'parent')
            if (window.parent) {
                window.parent.location.href = query.get('q');
            } else {
                window.location.replace(query.get('q'));
            }  
        }else{
           history.push('/transaction')
        }
    }, [])

    return (
        <div></div>
    );
}

export default RedirectTo;
